import React from 'react';

import { graphql } from 'gatsby';

import Footer from '../components/Footer/Footer';
import MaxWidthWrapper from '../components/MaxWidthWrapper';
import styled from 'styled-components';

import SEO from '../components/SEO';

import Header from '../components/Header/header';
import Particles from 'react-tsparticles';

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
`;

const SuccessParticles = styled(Particles)`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: transparent;
  top: 0;
`;
export default ({ data }) => {
  return (
    <>
      <SEO title="Success"></SEO>
      <Header />
      <MaxWidthWrapper>
        <BodyWrapper>
          <h2>Success 🎉</h2>
          <p>Thank you for confirming your email</p>
          <SuccessParticles
            id="tsparticles"
            options={{
              background: {
                image: '',
                position: '50% 50%',
                repeat: 'no-repeat',
                size: 'cover',
                opacity: 1,
              },

              detectRetina: true,
              fpsLimit: 60,
              infection: {
                cure: false,
                delay: 0,
                enable: false,
                infections: 0,
                stages: [],
              },

              particles: {
                collisions: {
                  enable: false,
                  mode: 'bounce',
                },
                color: {
                  value: '#000',
                  animation: {
                    enable: false,
                    speed: 1,
                    sync: true,
                  },
                },

                move: {
                  angle: 90,
                  attract: {
                    enable: true,
                    rotate: {
                      x: 600,
                      y: 1200,
                    },
                  },
                  direction: 'bottom',
                  enable: true,
                  noise: {
                    delay: {
                      random: {
                        enable: false,
                        minimumValue: 0,
                      },
                      value: 0,
                    },
                    enable: false,
                  },
                  outMode: 'out',
                  random: false,
                  speed: 2,
                  straight: false,
                  trail: {
                    enable: false,
                    length: 10,
                    fillColor: {
                      value: '#000000',
                    },
                  },
                  vibrate: false,
                  warp: false,
                },
                number: {
                  density: {
                    enable: false,
                    area: 800,
                    factor: 1000,
                  },
                  limit: 0,
                  value: 0,
                },
                opacity: {
                  animation: {
                    enable: false,
                    minimumValue: 0.1,
                    speed: 1,
                    sync: false,
                  },
                  random: {
                    enable: false,
                    minimumValue: 1,
                  },
                  value: 0.5,
                },
                rotate: {
                  animation: {
                    enable: false,
                    speed: 0,
                    sync: false,
                  },
                  direction: 'clockwise',
                  path: false,
                  random: false,
                  value: 0,
                },
                shadow: {
                  blur: 0,
                  color: {
                    value: '#000000',
                  },
                  enable: false,
                  offset: {
                    x: 0,
                    y: 0,
                  },
                },
                shape: {
                  options: {
                    polygon: {
                      nb_sides: 5,
                    },
                    star: {
                      nb_sides: 5,
                    },
                    image: {
                      src:
                        'https://cdn.matteobruni.it/images/particles/github.svg',
                      width: 100,
                      height: 100,
                    },
                    images: {
                      src:
                        'https://cdn.matteobruni.it/images/particles/github.svg',
                      width: 100,
                      height: 100,
                    },
                  },
                  type: 'circle',
                },
                size: {
                  animation: {
                    destroy: 'none',
                    enable: false,
                    minimumValue: 0.1,
                    speed: 40,
                    startValue: 'max',
                    sync: false,
                  },
                  random: {
                    enable: true,
                    minimumValue: 1,
                  },
                  value: 5,
                },
                stroke: {
                  width: 0,
                  color: {
                    value: '#000000',
                    animation: {
                      enable: false,
                      speed: 1,
                      sync: true,
                    },
                  },
                },
                twinkle: {
                  particles: {
                    enable: true,
                    frequency: 0.05,
                    opacity: 1,
                  },
                },
              },
              pauseOnBlur: true,
              emitters: [
                {
                  direction: 'top-right',
                  life: {
                    duration: 0.1,
                    count: 1,
                  },
                  rate: {
                    quantity: 100,
                    delay: 0.1,
                  },
                  particles: {
                    shape: {
                      type: 'square',
                    },
                    color: {
                      random: true,
                      value: '#f00',
                    },
                    lineLinked: {
                      enable: true,
                    },
                    opacity: {
                      value: 0.3,
                    },

                    rotate: {
                      value: 0,
                      random: true,
                      direction: 'counter-clockwise',
                      animation: {
                        enable: true,
                        speed: 15,
                        sync: false,
                      },
                    },
                    size: {
                      value: 10,
                      random: {
                        enable: true,
                      },
                    },
                    move: {
                      speed: 50,

                      random: true,
                      outMode: 'destroy',
                    },
                  },
                  position: {
                    x: 0,
                    y: 50,
                  },
                },
                {
                  direction: 'top-left',
                  life: {
                    duration: 0.1,
                    count: 1,
                  },
                  rate: {
                    quantity: 100,
                    delay: 0.1,
                  },
                  particles: {
                    shape: {
                      type: 'square',
                    },
                    rotate: {
                      value: 0,
                      random: true,
                      direction: 'clockwise',
                      animation: {
                        enable: true,
                        speed: 15,
                        sync: true,
                      },
                    },
                    color: {
                      value: '#00f',
                    },
                    lineLinked: {
                      enable: false,
                    },
                    opacity: {
                      value: 0.5,
                    },
                    size: {
                      value: 15,
                      random: false,
                    },
                    move: {
                      speed: 20,
                      random: false,

                      outMode: 'destroy',
                    },
                  },
                  position: {
                    x: 100,
                    y: 50,
                  },
                },
              ],
            }}
          />
        </BodyWrapper>
      </MaxWidthWrapper>
      <Footer></Footer>
    </>
  );
};

export const query = graphql`
  query {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { type: { eq: "article" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            published
            slug
            date(formatString: "MMMM DD, YYYY")
          }
          excerpt(pruneLength: 280)
        }
      }
    }
  }
`;
